import { useEffect, useRef, useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button, useMediaQuery, useTheme,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import jsPDF from 'jspdf';
import Head1Img from '../../assets/img/head1.png';
import Footer1Img from '../../assets/img/footer1.png';
import HeadLogo from '../../assets/img/logo-main.png';
import LocationPinIcon from '../../assets/img/location-pin.png';
import MailIcon from '../../assets/img/mail.png';
import PhoneIcon from '../../assets/img/phone-call.png';
import dayjs from 'dayjs';

const CertificateDialog = ({ certificateDialogOpen, handleCertificateDialogClose, jobData }) => {

    const CertRef = useRef(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const maxWidth = '900px';

    const doc = new jsPDF('p', 'px', 'a4', true);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize('16px');

    const [checklistName, setChecklistName] = useState('');

    const curdate = new Date();

    useEffect(() => {

        if (jobData.service.product_code === 'PROD3LMSM') {
            setChecklistName('Electrical');
        } else if (jobData.service.product_code === 'PROD4LMSM') {
            setChecklistName('Smoke Alarm');
        } else if (jobData.service_type === 'ES') {
            setChecklistName('Electrical');
        } else if (jobData.service_type === 'GS') {
            setChecklistName('Gas');
        }
    }, [jobData.service.product_code, jobData.service_type]);

    const handleGeneratePdf = () => {

        doc.setProperties({
            title: "certificate_" + curdate.getFullYear() + ".pdf"
        }).html(CertRef.current, {
            autoPaging: 'text',
            margin: [20, 20, 20, 20],
            callback: function (doc) {
                doc.setFont('helvetica', 'normal');
                doc.output('dataurlnewwindow', { filename: checklistName + "_safety_check_certificate_" + curdate.getFullYear() + ".pdf" });
            },
            html2canvas: {
                allowTaint: true,
                letterRendering: true,
                logging: false,
                scale: 0.45, // Adjust the scale to fit content
            }
        });

    };

    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth="md"
            open={certificateDialogOpen}
            onClose={handleCertificateDialogClose}
            scroll='body'
        >
            <DialogTitle className='p-0'>
                <IconButton
                    aria-label="close"
                    onClick={handleCertificateDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: 'black',
                        zIndex: '1000',
                        backgroundColor: 'white',
                        opacity: 0.9
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent ref={CertRef} className='p-0'>
                <div className='container' style={{ backgroundColor: '#FFFFFF', fontSize: '16px', textAlign: 'left', fontFamily: 'helvetica', width: maxWidth }}>
                    <div className='text-center pt-3'>
                        <div className='d-flex'>
                            <img src={Head1Img} alt='Head1Img' />
                            <div className='ms-auto pt-3'><img src={HeadLogo} alt='HeadLogo' style={{ height: '100px' }} /></div>
                        </div>
                        <h4 style={{ fontFamily: 'helvetica' }}>Certificate of&nbsp;{checklistName}&nbsp;Safety Compliance</h4>
                    </div>

                    <div className='mt-5 text-center'>
                        <h5 style={{ fontFamily: 'helvetica' }}>
                            This is to certify that <span className='text-theme'>{jobData.technician.prefix && jobData.technician.prefix + '.'} {jobData.technician.firstname && jobData.technician.firstname} {jobData.technician.lastname && jobData.technician.lastname}</span> has<br />
                            successfully completed the&nbsp;<span className='text-theme2'>{checklistName}&nbsp;Safety Check</span><br />
                            conducted by Safety Ninja.<br />
                            Date: <span className='text-theme2'>{jobData.service.service_date && dayjs(jobData.service.service_date).format('DD/MM/YYYY')}</span><br />
                            Property Address: <span className='text-theme2'>{jobData.service.property.address1}, {jobData.service.property.address2 && jobData.service.property.address2 + ','} {jobData.service.property.suburb}, {jobData.service.property.state} - {jobData.service.property.postcode}</span><br />
                            Electrician License Number: <span className='text-theme2'>{jobData.technician.license_number && jobData.technician.license_number}</span>
                        </h5>
                        <p>&nbsp;</p>
                        <Typography
                            sx={{ display: 'block', marginTop: '15px', marginBottom: '15px', fontSize: '20px' }}
                            component="div"
                            variant="subtitle-1"
                        >This certificate is awarded for passing the safety inspection process as<br />
                            per the standards set by Safety Ninja. The inspection ensures<br />
                            compliance with all relevant electrical safety regulations in Victoria.</Typography>

                        <h5 className='text-start' style={{ marginTop: '100px', marginLeft: '40px', padding: '5px 35px', fontFamily: 'helvetica' }}>Sincerely,</h5>
                        <div className='text-start' style={{ marginBottom: '200px', marginLeft: '40px', padding: '5px 35px', fontSize: '20px', fontFamily: 'helvetica' }}>
                            Safety Ninja Team
                        </div>
                    </div>
                    <div className='mt-5 container pl-5 pr-5'>
                        <div className='row'>
                            <div className='col-4 d-flex justify-content-center'>
                                <img src={PhoneIcon} alt='PhoneIcon' style={{ width: '24px', height: '24px', marginRight: '10px' }} />
                                <div>1300 175 528</div>
                            </div>
                            <div className='col-4 d-flex justify-content-center'>
                                <img src={MailIcon} alt='MailIcon' style={{ width: '24px', height: '24px', marginRight: '10px' }} />
                                <div>www.safetyninja.com.au <br />
                                    info@safetyninja.com.au</div>
                            </div>
                            <div className='col-4 d-flex justify-content-center'>
                                <img src={LocationPinIcon} alt='LocationPinIcon' style={{ width: '24px', height: '24px', marginRight: '10px' }} />
                                <div>11/17 Baretta Road <br />
                                    Ravenhall, VIC-3023</div>
                            </div>
                        </div>
                    </div>
                    <div><img src={Footer1Img} alt="footerimg" /></div>
                </div>
            </DialogContent>
            <DialogActions className='p-4'>
                <Button onClick={handleGeneratePdf}>
                    Generate PDF
                </Button>
                <Button onClick={handleCertificateDialogClose}>Close</Button>
            </DialogActions>

        </Dialog >
    );
}

export default CertificateDialog;