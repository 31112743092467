import { useEffect, useState, useRef, useContext, forwardRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { axiosFunction } from '../../common';
import {
    Box, CircularProgress, Fade, Typography, Button, TextField,
    Collapse, Alert, IconButton, Autocomplete, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide
} from "@mui/material";
import { Close, Remove, Add } from '@mui/icons-material';
import { InvoiceContext } from "../../pages/invoices";
import { Base64 } from 'js-base64';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const InvoiceForm = (props) => {
    const navigate = useNavigate(null);
    //const _role = localStorage.getItem("_role");
    const { formField, setFormField, userOptions, handleDialogClose, saveOption, setSaveOption, invoiceFor, setInvoiceFor, invItems, ownerCredit, setOwnerCredit, formChangeFlag, setFormChangeFlag } = useContext(InvoiceContext);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loader, setLoader] = useState(true);
    const [loaderText, setLoaderText] = useState(false);
    const [loaderTextSend, setLoaderTextSend] = useState(false);
    const [propertyOptions, setPropertyOptions] = useState(null);
    const [invoiceItems, setInvoiceItems] = useState(invItems.items);
    const invoiceFormRef = useRef();
    const [confirmopen, setConfirmOpen] = useState(false);
    const [delItem, setDelItem] = useState('');

    const { register, control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            items: invoiceItems
        }
    });
    const {
        fields,
        append,
        remove
    } = useFieldArray({
        control,
        name: "items"
    });
    // const curDate = new Date();
    const actionFun = formField.action;

    useEffect(() => {
        setLoader(false);
        // if (actionFun === 'edit') updateAmount(ownerCredit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionFun]);

    const checkFormChange = () => {
        setFormChangeFlag(true);
    };

    const getSelectedItem = (id) => {
        //console.log(userOptions);   
        // eslint-disable-next-line
        const item = userOptions.filter(a => a.is_landlord === 'Y').find((opt) => {
            if (opt && id && opt.id === id) {
                return opt;
            }
        });

        return item || null;
    }

    const getSelectedPropertyItem = (id) => {
        //console.log(propertyOptions);   
        // eslint-disable-next-line
        const item = propertyOptions && propertyOptions.find((opt) => {
            if (opt && id && opt.id === id) {
                return opt;
            }
        });

        return item || null;
    }

    const updateAmount = (credit, newFormValues) => {
        var subtotal = 0;
        var tax_rate = 0;
        var total = 0;
        var creditAmt = 0;
        var balanceCredit = 0;

        if (newFormValues) {
            newFormValues.map(element => subtotal = (element.amount === '') ? parseFloat(subtotal) : parseFloat(subtotal) + parseFloat(element.amount));
        } else {
            invoiceItems.map(element => subtotal = (element.amount === '') ? parseFloat(subtotal) : parseFloat(subtotal) + parseFloat(element.amount));
        }
        if (credit > 0 && subtotal > 0) {
            if (parseFloat(credit) > parseFloat(subtotal)) {
                creditAmt = parseFloat(subtotal) - parseFloat(formField.extCreditAmt) + parseFloat(formField.extCreditAmt);
                balanceCredit = parseFloat(credit) - (parseFloat(subtotal) - parseFloat(formField.extCreditAmt));
                if (actionFun === 'edit') {
                    setOwnerCredit(parseFloat(formField.extBalanceCredit) + (parseFloat(formField.extCreditAmt) - parseFloat(subtotal)));
                }
            } else {
                creditAmt = parseFloat(credit);
            }
            tax_rate = (parseFloat(subtotal) - parseFloat(creditAmt)) * (10 / 100);
            total = (parseFloat(subtotal) - parseFloat(creditAmt)) + parseFloat(tax_rate);
        } else {
            tax_rate = parseFloat(subtotal) * (10 / 100);
            total = parseFloat(subtotal) + parseFloat(tax_rate);
        }
        setFormField((prev) => ({ ...prev, subtotal: subtotal, tax_rate: tax_rate, total: total, creditAmt: creditAmt, balanceCredit: balanceCredit }));
    }

    const handleFieldChange = (i, e, fld) => {
        let newFormValues = [...invoiceItems];

        if (fld === 'quantity' || fld === 'charge') {
            if (parseInt(e.target.value) === 0 || e.target.value === '') e.target.value = 1;

            if (fld === 'quantity' && (parseInt(e.target.value) > 100 || !/^[0-9]+$/.test(e.target.value))) return false;
            if (fld === 'charge' && (e.target.value.length > 7 || !/^[0-9.]+$/.test(e.target.value))) return false;

            newFormValues[i][fld] = e.target.value;

            newFormValues[i]['amount'] = newFormValues[i]['quantity'] * newFormValues[i]['charge'];
            updateAmount(ownerCredit);
            setInvoiceItems(newFormValues);
            checkFormChange();
        } else {
            newFormValues[i][fld] = e.target.value;
            setInvoiceItems(newFormValues);
            checkFormChange();
        }

    }


    const addInvoiceItem = () => {
        append({ description: "", quantity: 1, charge: "", amount: "" });
        setInvoiceItems([...invoiceItems, { description: "", quantity: 1, charge: "", amount: "" }]);
        checkFormChange();
    }

    const removeItem = (i) => {
        setDelItem(i);
        if (actionFun === 'edit') {
            setConfirmOpen(true);
        } else {
            removeInvoiceItem();
        }
    }

    const removeInvoiceItem = () => {
        remove(delItem);
        let newFormValues = [...invoiceItems];
        newFormValues.splice(delItem, 1);
        setInvoiceItems(newFormValues);
        updateAmount(ownerCredit, newFormValues);
        checkFormChange();
        handleConfirmClose();
    }

    const handleConfirmClose = () => {
        if (actionFun === 'edit') {
            setConfirmOpen(false);
        }
        setDelItem('');
    };

    const loadProperties = async (owner_id) => {
        try {
            // console.log(owner_id);
            if (owner_id) {
                var params = { owner_id: owner_id };
                const propertyDataList = await axiosFunction('properties', 'get', params);
                if (propertyDataList.status === 'success') {
                    setPropertyOptions(propertyDataList.data);
                } else {
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage(propertyDataList.message);
                }
            } else {
                setPropertyOptions(null);
            }

        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    }


    const onInvoiceSubmit = async (data) => {
        try {
            if (formChangeFlag || saveOption === 'send') {
                setLoaderTextSend(saveOption === 'send' ? true : false);
                setLoaderText(saveOption === 'save' ? true : false);
                let saveResponse = null;

                data.items = invoiceItems;
                data.saveOption = saveOption;
                data.fields = formField;

                if (actionFun === 'edit') {
                    data._method = 'PUT';
                    data.invoice_id = formField.invoice_id;
                    saveResponse = await axiosFunction('invoices/' + formField.invoice_id, 'post', data);
                } else if (actionFun === 'add') {
                    data.owner_id = invoiceFor.owner_id;
                    data.property_id = invoiceFor.property_id;
                    saveResponse = await axiosFunction('invoices', 'post', data);
                }

                if (saveResponse.status === 'success') {
                    props.mainAlertOpen(true);
                    props.mainAlertStatus('success');
                    props.mainAlertMessage(saveResponse.message);
                    props.mainAlertAutoClose();
                    setLoaderTextSend(false);
                    setLoaderText(false);
                    handleDialogClose();
                    window.open('/generateinvoice/' + Base64.encode(saveResponse.item.id, true), '_blank');
                } else {
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage(saveResponse.message);
                    document.querySelector('#invoiceFrm').scrollIntoView({ behavior: 'smooth' });
                    setLoaderTextSend(false);
                    setLoaderText(false);
                }
            } else {
                handleDialogClose();
                setLoaderTextSend(false);
                setLoaderText(false);
            }
        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoaderTextSend(false);
            setLoaderText(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    };

    return (
        <>
            <div className='header-top text-white p-3 d-flex'>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleDialogClose}
                    aria-label="close"
                >
                    <Close />
                </IconButton>
                <Typography
                    variant='h5'
                    className='text-white'
                    sx={{ marginLeft: '30px' }}
                >
                    {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} Invoice

                </Typography>
            </div>
            {loader && <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                <Fade
                    in={loader}
                    unmountOnExit
                >
                    <CircularProgress />
                </Fade>
            </Box>}
            <Dialog
                open={confirmopen}
                TransitionComponent={Transition}
                sx={{ zIndex: 1400 }}
            >
                <DialogTitle>Are you sure you want to delete this item?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        If yes, the item will be deleted and no longer be exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-secondary" onClick={() => handleConfirmClose()}>Cancel</Button>
                    <Button className="themeholy-btn btn-primary" onClick={() => removeInvoiceItem()}>Yes</Button>
                </DialogActions>
            </Dialog>
            {!loader && <div className="container pt-3">
                <Collapse in={alertOpen}>
                    <Alert variant="filled" severity={alertStatus}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlertOpen(false);
                                }}
                            >
                                <Close fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {alertMessage}
                    </Alert>
                </Collapse>
                <Box component="form" ref={invoiceFormRef} id="invoiceFrm" name="invoiceFrm" noValidate autoComplete="off" onSubmit={handleSubmit(onInvoiceSubmit)}>
                    <div className="row">
                        {actionFun === 'edit' ? <><div className="col-md-12 p-4">
                            <strong>Invoice Number:</strong> {formField.invoice_number ? formField.invoice_number : '#####'}
                        </div>
                            <div className="col-md-12 p-4">
                                <div className="row">
                                    <div className="col-12 col-md-6"><strong>Invoice To:</strong> {invItems.property.propertyowner.prefix ? invItems.property.propertyowner.prefix + '.' : ''} {invItems.property.propertyowner.firstname} {invItems.property.propertyowner.lastname}</div>
                                    <div className="col-12 col-md-6 text-md-end"><strong>Property:</strong> {invItems.property.address1}{invItems.property.address2 ? ', ' + invItems.property.address2 : ''}, {invItems.property.suburb}, {invItems.property.state} - {invItems.property.postcode}</div>
                                </div>
                            </div>
                        </> :
                            <div className="col-md-11 m-auto">
                                <h5>Invoice To:</h5>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Controller
                                            id="owner_id" name="owner_id"
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <Autocomplete
                                                    disablePortal
                                                    options={userOptions.filter(a => a.is_landlord === "Y")}
                                                    getOptionLabel={(option) => option.label ? option.label : ''}
                                                    sx={{ width: '100%' }}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" {...props} key={"owner_" + option.id}>
                                                            {option.label}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => <TextField {...params} label="Choose Landlord *" variant="outlined" error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                        {...register(`owner_id`, { required: 'Landlord is required' })}
                                                    />}
                                                    onChange={(_, data) => {
                                                        field.onChange(data ? data.id : null)
                                                        setInvoiceFor((prev) => ({ ...prev, owner_id: data ? data.id : null, property_id: null }))
                                                        checkFormChange();
                                                        loadProperties(data?.id);
                                                        setOwnerCredit(data?.credit);
                                                        updateAmount(data?.credit);
                                                    }}
                                                    value={invoiceFor.owner_id ? getSelectedItem(invoiceFor.owner_id) : null}
                                                    isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Controller
                                            id="property_id"
                                            name="property_id"
                                            control={control}
                                            /* rules={{
                                                required: "Property is required"
                                            }} */
                                            render={({ field, fieldState }) => (
                                                <Autocomplete
                                                    disablePortal
                                                    options={propertyOptions ? propertyOptions : []}
                                                    getOptionLabel={(option) => option && (option.address1 ? option.address1 + ', ' : '') + (option.address2 ? option.address2 + ', ' : '') + option.suburb + ', ' + option.state + ' - ' + option.postcode}
                                                    sx={{ width: '100%' }}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" {...props} key={"property_" + option.id}>
                                                            {option.address1 ? option.address1 : ''}, {option.address2 ? option.address2 + ', ' : ''}<br />{option.suburb}, {option.state} - {option.postcode}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => <TextField {...params} label="Choose Property *" variant="outlined"
                                                        error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                        {...register(`property_id`, { required: 'Property is required' })}
                                                    />}
                                                    onChange={(_, data) => {
                                                        field.onChange(data ? data.id : '')
                                                        setInvoiceFor((prev) => ({ ...prev, property_id: data ? data.id : '' }))
                                                        checkFormChange();
                                                    }}
                                                    value={invoiceFor.property_id ? getSelectedPropertyItem(invoiceFor.property_id) : null}
                                                    isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-md-11 m-auto">
                            <div className="row">
                                <div className="col-md-11">
                                    {/* <Button type="button" autoFocus className="themeholy-btn btn-primary m-3" onClick={() => addInvoiceItem()}>
                                        Add Item
                                    </Button> */}
                                    <h5 className="pt-5">Add Products:</h5>
                                </div>
                            </div>
                            {/* <div className="row p-4" style={{ border: '1px solid #ccc' }}>
                                <div className="col-5 fw-bold text-center">Description</div>
                                <div className="col-2 fw-bold text-center">Qty</div>
                                <div className="col-2 fw-bold text-center">Charge</div>
                                <div className="col-2 fw-bold text-center">Amount</div>
                                <div className="col-1 fw-bold m-auto"></div>
                            </div> */}
                            {fields.map((item, index) => (
                                <div className="row" key={index} style={{ border: '1px solid #ccc', borderTop: index ? '0px' : '1px solid #ccc' }}>
                                    <div className="col-6 col-md-4 m-auto d-flex">
                                        <Controller
                                            render={({ field }) => <TextField {...field}
                                                label="Description"
                                                fullWidth variant="outlined"
                                                {...register(`items.${index}.description`, { required: 'Description is required' })}
                                                onChange={(e) => handleFieldChange(index, e, 'description')}
                                                inputProps={{ value: invoiceItems[index].description, maxLength: 100 }}
                                                error={errors.items && errors.items[index]?.description ? true : false}
                                                helperText={errors.items && errors.items[index]?.description?.message}
                                            />
                                            }
                                            name={`items.${index}.description`}
                                            control={control}
                                        />
                                    </div>
                                    <div className="col-6 col-md-3 m-auto d-flex align-items-center">
                                        <Remove color={parseInt(invoiceItems[index]['quantity']) > 1 ? 'primary' : 'disabled'} style={{ border: '1px solid #ccc', borderRadius: '50%', margin: '10px', cursor: parseInt(invoiceItems[index]['quantity']) > 1 ? 'pointer' : 'default' }} onClick={() => {
                                            let newFormValues = [...invoiceItems];
                                            newFormValues[index]['quantity'] = parseInt(newFormValues[index]['quantity']) > 1 ? parseInt(newFormValues[index]['quantity']) - 1 : newFormValues[index]['quantity'];

                                            if (parseFloat(newFormValues[index]['charge']) > 0) {
                                                newFormValues[index]['amount'] = parseInt(newFormValues[index]['quantity']) * parseFloat(newFormValues[index]['charge']);
                                            }
                                            updateAmount(ownerCredit);
                                            setInvoiceItems(newFormValues);
                                            checkFormChange();
                                        }} />
                                        <Controller
                                            render={({ field, fieldState }) => <TextField {...field}
                                                type="number"
                                                label="Qty"
                                                fullWidth variant="outlined"
                                                {...register(`items.${index}.quantity`, { required: 'Quantity is required' })}
                                                onChange={(e) => handleFieldChange(index, e, 'quantity')}
                                                inputProps={{ value: invoiceItems[index].quantity, step: 1 }}
                                                error={errors.items && errors.items[index]?.quantity ? true : false}
                                                helperText={errors.items && errors.items[index]?.quantity?.message}
                                            />
                                            }
                                            name={`items.${index}.quantity`}
                                            control={control}
                                        />
                                        <Add color={parseInt(invoiceItems[index]['quantity']) < 100 ? 'primary' : 'disabled'} style={{ border: '1px solid #ccc', borderRadius: '50%', margin: '10px', cursor: parseInt(invoiceItems[index]['quantity']) < 100 ? 'pointer' : 'default' }} onClick={() => {
                                            let newFormValues = [...invoiceItems];

                                            newFormValues[index]['quantity'] = parseInt(newFormValues[index]['quantity']) < 100 ? parseInt(newFormValues[index]['quantity']) + 1 : newFormValues[index]['quantity'];

                                            if (parseFloat(newFormValues[index]['charge']) > 0) {
                                                newFormValues[index]['amount'] = parseInt(newFormValues[index]['quantity']) * parseFloat(newFormValues[index]['charge']);
                                            }

                                            updateAmount(ownerCredit);
                                            setInvoiceItems(newFormValues);
                                            checkFormChange();
                                        }} />
                                    </div>
                                    <div className="col-6 col-md-2 m-auto text-end">
                                        <Controller
                                            render={({ field, fieldState }) => <TextField {...field}
                                                type="number"
                                                label="Charge"
                                                fullWidth variant="outlined"
                                                {...register(`items.${index}.charge`, { required: 'Charge is required' })}
                                                onChange={(e) => handleFieldChange(index, e, 'charge')}
                                                value={invoiceItems[index].charge}
                                                error={errors.items && errors.items[index]?.charge ? true : false}
                                                helperText={errors.items && errors.items[index]?.charge?.message}
                                            />
                                            }
                                            name={`items.${index}.charge`}
                                            control={control}
                                        />
                                    </div>
                                    <div className="col-6 col-md-3 d-flex gap-3 m-auto text-end">
                                        <TextField
                                            type="number"
                                            label="Amount"
                                            fullWidth variant="outlined"
                                            inputProps={{ maxLength: 15, readOnly: true }}
                                            value={parseFloat(invoiceItems[index].amount).toFixed(2)}
                                        />
                                        {
                                            index ? <div className="d-flex gap-3 m-auto">{invoiceItems.length <= (index + 1) && <Link to='#' onClick={() => addInvoiceItem()}><i className="fa fa-circle-plus" ></i></Link>} <Link to='#' onClick={() => removeItem(index)}><i className="fa fa-trash" ></i></Link></div>
                                                : <div className="d-flex gap-3 m-auto">{invoiceItems.length <= (index + 1) && <Link to='#' onClick={() => addInvoiceItem()}><i className="fa fa-circle-plus" ></i></Link>} {invoiceItems.length > 1 && <Link to='#' onClick={() => removeItem(index)}><i className="fa fa-trash" ></i></Link>}</div>
                                        }
                                    </div>
                                </div>
                            ))}

                            <div className="row fs-xxl fw-bold" style={{ border: '1px solid #ccc', borderTop: '0px' }}>
                                <div className="col-9 text-end p-3">
                                    Subtotal :
                                </div>
                                <div className="col-3 p-3">
                                    {formField.subtotal.toFixed(2)}
                                </div>
                                {ownerCredit > 0 &&
                                    <>
                                        <div className="col-9 text-end p-3">
                                            Available Credit :
                                        </div>
                                        <div className="col-3 p-3">
                                            {ownerCredit.toFixed(2)}
                                        </div>
                                        <div className="col-9 text-end p-3">
                                            Applied Credit <span className="fw-normal">({formField.subtotal.toFixed(2)} - {formField.creditAmt.toFixed(2)})</span> :
                                        </div>
                                        <div className="col-3 p-3">
                                            {(formField.subtotal - formField.creditAmt).toFixed(2)}
                                        </div>
                                    </>
                                }
                                <div className="col-9 text-end p-3">
                                    GST 10% :
                                </div>
                                <div className="col-3 p-3">
                                    {formField.tax_rate.toFixed(2)}
                                </div>
                                <div className="col-9 text-end p-3">
                                    <h5>Total :</h5>
                                </div>
                                <div className="col-3 p-3">
                                    <h5>{formField.total.toFixed(2)}</h5>
                                </div>
                            </div>
                        </div>
                        {formField.balanceCredit > 0 && <div className="col-md-11 m-auto text-theme p-5">
                            Your balance credit {formField.balanceCredit.toFixed(2)}
                        </div>
                        }
                        <div className="col-md-11 m-auto text-center mt-3 mb-5">
                            <div className="row">
                                <div className="col-12 col-md-4 m-auto p-1">
                                    <Button type="button" className="themeholy-btn btn-secondary bg-black w-100" onClick={handleDialogClose}>Close</Button>
                                </div>
                                <div className="col-12 col-md-4 m-auto p-1">
                                    <Button type="submit" className="col-11 col-md-4 themeholy-btn btn-secondary w-100" onClick={() => setSaveOption('save')} disabled={loaderText}>{loaderText ? 'Submitting' : 'Save & Exit'}</Button>
                                </div>
                                <div className="col-12 col-md-4 m-auto p-1">
                                    <Button type="submit" className="themeholy-btn btn-primary w-100" onClick={() => setSaveOption('send')} disabled={loaderTextSend}>{loaderTextSend ? 'Submitting' : 'Save & Send Invoice'}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>

            </div>}

        </>
    )
}

export default InvoiceForm;