import { useState, useEffect, createContext, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import SiteHeader from "../components/SiteHeader";
import Footer from "../components/Footer";
import { Button, Drawer, Collapse, Alert, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { axiosFunction } from "../common";
import { Invoice } from "../services/PropertyData";
import InvoiceForm from "../components/Jobs/InvoiceForm";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const InvoiceContext = createContext(null);

const Invoices = () => {
    const navigate = useNavigate(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [formField, setFormField] = useState({ subtotal: 0, creditAmt: 0, tax_rate: 0, total: 0, balanceCredit: 0 });
    const [userOptions, setUserOptions] = useState(null);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [saveOption, setSaveOption] = useState('');
    const [invoiceFor, setInvoiceFor] = useState({ owner_id: '', property_id: '' });
    const [updateMainList, setUpdateMainList] = useState(false);
    const [invItems, setInvItems] = useState({ items: [{ description: "", quantity: 1, charge: "", amount: "" }] });
    const [ownerCredit, setOwnerCredit] = useState(0);
    const [formChangeFlag, setFormChangeFlag] = useState(false);
    //const [serviceUsersOptions, setServiceUsersOptions] = useState(null);
    //const [jobStatusOptions, setJobStatusOptions] = useState(null);
    const [cancelInvoice, setCancelInvoice] = useState({});
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [cancelLoader, setCancelLoader] = useState(false);
    const [sendLoader, setSendLoader] = useState(false);
    const [invoiceSent, setInvoiceSent] = useState('N');
    const [invoiceId, setInvoiceId] = useState('');
    const [invoiceConfirmOpen, setInvoiceConfirmOpen] = useState(false);
    const [xeroConfirmOpen, setXeroConfirmOpen] = useState(false);
    const [xeroSent, setXeroSent] = useState('N');
    const [xeroLoader, setXeroLoader] = useState(false);
    const [xeroRotate, setXeroRotate] = useState('');

    const loadData = async () => {
        try {
            const userDataList = await axiosFunction('auth/users', 'post', {});
            if (userDataList.status === 'success') {
                setUserOptions(userDataList.data);
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(userDataList.message);
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAdd = () => {
        setFormField({ subtotal: 0, creditAmt: 0, tax_rate: 0, total: 0, balanceCredit: 0, extCreditAmt: 0, extBalanceCredit: 0, action: 'add' });
        setInvoiceFor({ owner_id: '', property_id: '' });
        setOwnerCredit(0);
        setInvItems({ items: [{ description: '', quantity: 1, charge: '', amount: '' }] });
        setDrawerOpen(true);
    }

    const handleDialogClose = () => {
        setInvoiceFor({ owner_id: '', property_id: '' });
        setFormField({ subtotal: 0, creditAmt: 0, tax_rate: 0, total: 0, balanceCredit: 0 });
        setOwnerCredit(0);
        setSaveOption('');
        setUpdateMainList(formChangeFlag);
        setDrawerOpen(false);
    };

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleInvoiceCancel = (id) => {
        var data = {
            id: id,
            _method: 'PUT',
            action: 'cancel'
        }
        setCancelInvoice(data);
        setConfirmOpen(true);
    }

    const handleCancel = async () => {
        try {
            setCancelLoader(true);
            const cancelResponse = await axiosFunction('invoices/' + cancelInvoice.id, 'post', cancelInvoice);
            setAlertOpen(true);
            if (cancelResponse.status === 'success') {
                setAlertStatus('success');
                setAlertMessage(cancelResponse.message);
                handleAutoClose();
                setConfirmOpen(false);
                setCancelInvoice({});
                setUpdateMainList(true);
                setCancelLoader(false);
            } else {
                setAlertStatus('error');
                setAlertMessage(cancelResponse.message);
                setConfirmOpen(false);
                setCancelInvoice({});
                setCancelLoader(false);
            }

        } catch (e) {
            setConfirmOpen(false);
            setCancelInvoice({});
            setCancelLoader(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
        setCancelInvoice({});
    };

    const handleSendInvoice = (id, sent) => {
        setInvoiceSent(sent);
        setInvoiceId(id);
        setInvoiceConfirmOpen(true);
    }

    const handleSend = async () => {
        try {
            setSendLoader(true);
            const sendResponse = await axiosFunction('auth/sendinvoice', 'post', { invoice_id: invoiceId });
            setAlertOpen(true);
            if (sendResponse.status === 'success') {
                handleInvoiceConfirmClose();
                setAlertStatus('success');
                setAlertMessage(sendResponse.message);
                handleAutoClose();
                setUpdateMainList(true);
                setSendLoader(false);
            } else {
                handleInvoiceConfirmClose();
                setAlertStatus('error');
                setAlertMessage(sendResponse.message);
                setSendLoader(false);
            }

        } catch (e) {
            handleInvoiceConfirmClose();
            setSendLoader(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    }

    const handleInvoiceConfirmClose = () => {
        setInvoiceSent('N');
        setInvoiceId('');
        setInvoiceConfirmOpen(false);
    };

    const handleInvoiceXero = (id, xero_invoice_id) => {
        setInvoiceId(id);
        setXeroConfirmOpen(true);
        if (xero_invoice_id) {
            setXeroSent('Y');
        }
    }

    const handleSendXero = async () => {
        try {
            setXeroLoader(true);
            setXeroRotate(invoiceId);
            const xeroResponse = await axiosFunction('xero/createinvoice', 'post', { invoice_id: invoiceId });
            setAlertOpen(true);
            if (xeroResponse.status === 'success') {
                handleXeroConfirmClose();
                setAlertStatus('success');
                setAlertMessage(xeroResponse.message);
                handleAutoClose();
                setUpdateMainList(true);
                setXeroLoader(false);
            } else {
                handleXeroConfirmClose();
                setAlertStatus('error');
                setAlertMessage(xeroResponse.message);
                setXeroLoader(false);
            }

        } catch (e) {
            handleXeroConfirmClose();
            setXeroLoader(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    }

    const handleXeroConfirmClose = () => {
        setInvoiceId('');
        setXeroSent('N');
        setXeroConfirmOpen(false);
        setXeroRotate('');
    };

    return (
        <>
            <SiteHeader menu='invoices' />
            <div className="user-panel">
                <div className="title text-start">Invoices</div>
                <div className="container-fluid">
                    <Dialog
                        open={xeroConfirmOpen}
                        TransitionComponent={Transition}
                    >
                        <DialogTitle>Are you sure you want to synchronize this invoice with Xero?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {xeroSent === 'Y' && <span className="text-warning">Invoice has been already synchronized. </span>}
                                If yes, the invoice will be synchronize {xeroSent === 'Y' && '/update'} with Xero.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="themeholy-btn btn-secondary" onClick={handleXeroConfirmClose} disabled={xeroLoader}>Cancel</Button>
                            <Button className="themeholy-btn btn-primary" onClick={() => handleSendXero()} disabled={xeroLoader}>{xeroLoader ? <i className="fa fa-spin fa-gear"></i> : 'Yes'}</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={invoiceConfirmOpen}
                        TransitionComponent={Transition}
                    >
                        <DialogTitle>Are you sure you want to send this invoice?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {invoiceSent === 'Y' && <span className="text-warning">Invoice has been already sent. </span>}
                                If yes, the invoice will be sent{invoiceSent === 'Y' && ' again'}.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="themeholy-btn btn-secondary" onClick={handleInvoiceConfirmClose} disabled={sendLoader}>Cancel</Button>
                            <Button className="themeholy-btn btn-primary" onClick={() => handleSend()} disabled={sendLoader}>{sendLoader ? <i className="fa fa-spin fa-gear"></i> : 'Yes'}</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={confirmOpen}
                        TransitionComponent={Transition}
                    >
                        <DialogTitle>Are you sure you want to cancel this invoice?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                If yes, invoice will be cancelled from the records and no longer be exists. If any credit deducted for the cancelled invoice that will be refunded to the Landlord's account.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose} disabled={cancelLoader}>Cancel</Button>
                            <Button className="themeholy-btn btn-primary" onClick={() => handleCancel()} disabled={cancelLoader}>{cancelLoader ? <i className="fa fa-spin fa-gear"></i> : 'Yes'}</Button>
                        </DialogActions>
                    </Dialog>
                    <div className="row">
                        <div className="col-md-12">
                            <Collapse in={alertOpen}>
                                <Alert variant="filled" severity={alertStatus}
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setAlertOpen(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    sx={{ mb: 2, id: "alertmessage" }}
                                >
                                    {alertMessage}
                                </Alert>
                            </Collapse>
                        </div>
                        <div className="col-md-12 text-end">
                            <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Invoice</Button>
                        </div>
                        <div className="col-md-12">
                            <Invoice
                                updateTabList={updateMainList}
                                setUpdateTabList={setUpdateMainList}
                                setInvItems={setInvItems}
                                setFormField={setFormField}
                                setDrawerOpen={setDrawerOpen}
                                setOwnerCredit={setOwnerCredit}
                                handleInvoiceCancel={handleInvoiceCancel}
                                handleSendInvoice={handleSendInvoice}
                                handleInvoiceXero={handleInvoiceXero}
                                xeroRotate={xeroRotate}
                                setXeroRotate={setXeroRotate}
                            />
                        </div>
                    </div>

                </div>
            </div>
            <InvoiceContext.Provider value={
                {
                    formField, setFormField, userOptions,
                    handleDialogClose, saveOption, setSaveOption, invoiceFor, setInvoiceFor,
                    invItems, ownerCredit, setOwnerCredit, formChangeFlag, setFormChangeFlag
                }
            }>
                {drawerOpen && <Drawer anchor="right" open={drawerOpen} sx={{ zIndex: 1400 }} PaperProps={{ className: 'container p-0' }}>
                    <InvoiceForm mainAlertStatus={setAlertStatus} mainAlertOpen={setAlertOpen} mainAlertMessage={setAlertMessage} mainAlertAutoClose={handleAutoClose} />
                </Drawer>}
            </InvoiceContext.Provider >
            <Footer />
        </>
    );
}

export default Invoices;