import { useRef, useEffect, useState, Fragment } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
    Typography, Table, TableBody, TableRow, TableHead, TableFooter, TableCell, Box, AppBar, Toolbar, Button
} from '@mui/material';
import dayjs from 'dayjs';
import { axiosFunction, apiBaseUrl } from '../../common';
import jsPDF from 'jspdf';
import Banner from "../../assets/img/Report_Invoice_Header.png";

const Invoice = ({ upload, invoiceData }) => {
    const _token = localStorage.getItem("_token");
    const invoiceRef = useRef(null);
    const navigate = useNavigate(null);
    const comp = useLocation();
    const compArr = comp.pathname.split('/');
    const invoiceid = compArr[2];
    const viewBy = compArr[3] ? compArr[3] : '';
    const maxWidth = '900px';

    const [invoiceDetails, setInvoiceDetails] = useState({});

    const doc = new jsPDF('p', 'px', 'a4', true);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize('16px');

    const invoiceFetchData = async (invid) => {
        if (invid) {
            try {
                const invoiceData = await axiosFunction('auth/getinvoice', 'get', { invoice_id: invid, viewBy: viewBy });
                if (invoiceData.status === 'success') {
                    setInvoiceDetails(invoiceData.data);
                    if (_token && upload === 'Y') {
                        setTimeout(() => {
                            doc.setProperties({
                                title: "invoice_" + invid + ".pdf"
                            }).html(invoiceRef.current, {
                                autoPaging: 'text',
                                margin: [20, 20, 20, 20],
                                callback: async function (doc) {
                                    doc.setFont('helvetica', 'normal');
                                    var out = doc.output('datauristring');
                                    //doc.output('dataurlnewwindow', { filename: "invoice_" + invid + ".pdf" });
                                    if (out) {
                                        const pdfData = { invoice_id: invid, pdfContent: out }
                                        const response = await axiosFunction('invoices/uploadpdf', 'post', pdfData);

                                        if (response.status !== 'success') {
                                            if (response?.response?.status === 401) {
                                                localStorage.clear();
                                                navigate('/login');
                                            }
                                        }

                                    }
                                },
                                html2canvas: {
                                    allowTaint: true,
                                    letterRendering: true,
                                    logging: false,
                                    scale: 0.45, // Adjust the scale to fit content
                                }
                            });

                        }, 2000);
                    }
                } else {
                    /* if (invoiceData?.response?.status === 401) {
                        localStorage.clear();
                        navigate('/login');
                    } */
                }
            } catch (e) {
                if (e?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } else {
                    console.log(e);
                }
            }

        }
    }

    useEffect(() => {
        if (invoiceData) setInvoiceDetails(invoiceData)
        if (invoiceid) invoiceFetchData(invoiceid);
        // eslint-disable-next-line
    }, [invoiceid]);

    return (
        <>
            {
                invoiceDetails.id &&
                <Fragment>
                    {invoiceid &&
                        <AppBar sx={{ position: 'fixed' }} className="bg-theme">
                            <Toolbar className="d-flex flex-row-reverse">
                                <Link to={apiBaseUrl.replace('api/', '') + "public/storage/invoices/invoice_" + invoiceid + ".pdf"}><Button type="button" className="themeholy-btn btn-secondary">
                                    Download
                                </Button></Link>
                            </Toolbar>
                        </AppBar>
                    }
                    <Box ref={invoiceRef} style={{ backgroundColor: '#FFFFFF', fontSize: '16px', textAlign: 'left', padding: '15px', fontFamily: 'helvetica', width: maxWidth }} className="container" id='pdfcontent'>

                        <div className='text-center'><img alt="banner" src={Banner} width='100%' /></div>
                        <div className='container'>

                            <Typography
                                sx={{ display: 'block', margin: '15px 0px' }}
                                component="h3"
                                variant="subtitle-1"
                                className='text-theme'
                            >
                                Invoice
                            </Typography>
                            <div className='row'>
                                <div className='col-7 mb-3'>
                                    <div>
                                        <strong>Property Address:</strong>
                                        <Typography
                                            sx={{ display: 'block' }}
                                            variant="body"
                                        >
                                            {invoiceDetails.services && <>
                                                {invoiceDetails.services.property.address1 + ', '}
                                                {invoiceDetails.services.property.address2 && invoiceDetails.services.property.address2 + ','}<br />
                                                {invoiceDetails.services.property.suburb + ', '}
                                                {invoiceDetails.services.property.state + ' - ' + invoiceDetails.services.property.postcode}
                                            </>}

                                            {invoiceDetails.property && <>
                                                {invoiceDetails.property.address1 + ', '}
                                                {invoiceDetails.property.address2 && invoiceDetails.property.address2 + ','}<br />
                                                {invoiceDetails.property.suburb + ', '}
                                                {invoiceDetails.property.state + ' - ' + invoiceDetails.property.postcode}
                                            </>}

                                        </Typography>
                                    </div>
                                    <div className='mt-3'>
                                        <strong>Landlord:</strong>
                                        <Typography
                                            sx={{ display: 'block' }}
                                            variant="body"
                                        >
                                            {invoiceDetails.services && <>
                                                {invoiceDetails.services.property.propertyowner.prefix ? invoiceDetails.services.property.propertyowner.prefix + '. ' : ''} {invoiceDetails.services.property.propertyowner.firstname + ' ' + invoiceDetails.services.property.propertyowner.lastname}
                                            </>}

                                            {invoiceDetails.property && <>
                                                {invoiceDetails.property.propertyowner.prefix ? invoiceDetails.property.propertyowner.prefix + '. ' : ''} {invoiceDetails.property.propertyowner.firstname + ' ' + invoiceDetails.property.propertyowner.lastname}
                                            </>}

                                        </Typography>
                                    </div>
                                </div>
                                <div className='col-5'>
                                    <div className='row'>
                                        <div className='col-12 col-md-6 fw-bold text-md-end'>Invoice Date: </div>
                                        <div className='col-12 col-md-6 text-md-end'>{(invoiceDetails.invoice_date.split('/')[0].length > 4) ? dayjs(invoiceDetails.invoice_date).format('DD-MM-YYYY') : invoiceDetails.invoice_date}</div>
                                        <div className='col-12 col-md-6 fw-bold text-md-end'>Invoice #: </div>
                                        <div className='col-12 col-md-6 text-md-end'> {invoiceDetails.invoice_number}</div>
                                        {invoiceDetails.services && <>
                                            <div className='col-12 col-md-6 fw-bold text-md-end'>Service Date: </div>
                                            <div className='col-12 col-md-6 text-md-end'>{invoiceData ? invoiceData.services.service_date : dayjs(invoiceDetails.services.service_date).format('DD-MM-YYYY')}</div>
                                        </>}
                                    </div>
                                </div>
                                <div className='col-md-12 p-5 text-end'>
                                    <span style={{ borderRadius: '25px', textAlign: 'center', width: '100px', padding: '5px 15px', color: 'white', fontWeight: 'bold', backgroundColor: invoiceDetails.paid_status === 'Y' ? 'green' : (invoiceDetails.paid_status === 'N' ? 'orange' : 'red') }}>
                                        {invoiceDetails.paid_status === 'Y' ? 'PAID' : (invoiceDetails.paid_status === 'N' ? 'NOT PAID' : 'CANCELLED')}
                                    </span>
                                </div>
                                <div className='col-md-12'>
                                    <Table>

                                        {invoiceDetails.services && <>
                                            <TableHead className="bg-smoke text-body fs-md fw-bold">
                                                <TableRow>
                                                    <TableCell sx={{ width: '60%' }}><b>Description</b></TableCell>
                                                    <TableCell><b>Qty</b></TableCell>
                                                    <TableCell><b>Charge</b></TableCell>
                                                    <TableCell sx={{ width: '15%' }}><b>Subtotal</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        {invoiceDetails.description ? invoiceDetails.description : <>{invoiceDetails.property.address1}{invoiceDetails.property.address2 ? ', ' + invoiceDetails.property.address2 : ''}, {invoiceDetails.property.suburb}, {invoiceDetails.property.state} - {invoiceDetails.property.postcode}</>}
                                                    </TableCell>
                                                    <TableCell>1</TableCell>
                                                    <TableCell>{invoiceDetails.amount ? invoiceDetails.amount.toFixed(2) : '0.00'}</TableCell>
                                                    <TableCell className='text-end'>{invoiceDetails.amount ? invoiceDetails.amount.toFixed(2) : '0.00'}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </>}

                                        {invoiceDetails.property && <>
                                            <TableHead className="bg-smoke text-body fs-md fw-bold">
                                                <TableRow>
                                                    <TableCell sx={{ width: '60%' }}><b>Description</b></TableCell>
                                                    <TableCell><b>Qty</b></TableCell>
                                                    <TableCell><b>Charge</b></TableCell>
                                                    <TableCell sx={{ width: '15%' }}><b>Subtotal</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {invoiceDetails.items.map((item, index) =>
                                                    <TableRow key={'invrow_' + index}>
                                                        <TableCell>{item.description}</TableCell>
                                                        <TableCell>{item.quantity ? item.quantity : 1}</TableCell>
                                                        <TableCell>{item.charge.toFixed(2)}</TableCell>
                                                        <TableCell className='text-end'>{item.amount.toFixed(2)}</TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </>}

                                        <TableFooter>
                                            <TableRow>
                                                <TableCell rowSpan={5}>
                                                    <h6 className='sub-title bg-smoke2 p-1 m-0'>PLEASE FIND THE PAYMENT DETAILS:</h6>
                                                    <p className='text-theme' style={{ fontSize: '14px' }}>
                                                        Attention: Please mention Invoice number as a reference<br />
                                                        <strong>Invoice Number: {invoiceDetails.invoice_number}</strong>
                                                    </p>
                                                    <p className='text-theme2 fw-bold'>Payment To:</p>
                                                    <div className='text-theme2'>  KT and RP Pty Ltd <br />
                                                        T/A Safety Ninja </div>

                                                    <p className='text-theme2'>BSB 063027<br />
                                                        ACC 10230429
                                                    </p>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={3} className='bg-smoke' style={{ height: '40px' }}><h6 style={{ padding: 0, margin: 0 }}>Invoice Summary</h6></TableCell>
                                            </TableRow>
                                            {invoiceDetails.credit > 0 &&
                                                <TableRow>
                                                    <TableCell className='text-end' colSpan={2} style={{ height: '40px' }}><b>Credit</b></TableCell>
                                                    <TableCell className='fw-bold text-end'>{invoiceDetails.credit ? invoiceDetails.credit.toFixed(2) : '0.00'}</TableCell>
                                                </TableRow>
                                            }
                                            <TableRow>
                                                <TableCell className='text-end' colSpan={2} style={{ height: '40px' }}><b>Subtotal</b></TableCell>
                                                <TableCell className='text-end'>{(invoiceDetails.amount - invoiceDetails.credit).toFixed(2)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className='text-end' colSpan={2} style={{ height: '40px' }}><b>GST 10%</b></TableCell>
                                                <TableCell className='text-end'>{invoiceDetails.tax_rate ? invoiceDetails.tax_rate.toFixed(2) : '0.00'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className='text-end bg-smoke2' colSpan={2}><h6>Total Amount</h6></TableCell>
                                                <TableCell className='text-end bg-smoke2'><h6>{invoiceDetails.total ? invoiceDetails.total.toFixed(2) : '0.00'}</h6></TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </div>

                            </div>
                        </div>
                    </Box>
                </Fragment>
            }
        </>
    )
}
export default Invoice;